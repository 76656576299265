/**
 * Make all Modals draggable
 */
$(function() {

    $('body')
        .on('shown.bs.modal', function(event) {
            var $target = $(event.target).find('.modal-dialog');

            // Save the original location of the target (needed for reset)
            $target.data({
                'originalLeft': $target.css('left'),
                'originalTop': $target.css('top')
            });

            // Enable draggable on the modal, with the header as handle
            $target.draggable({handle: '.modal-header'});
        })
        .on('hidden.bs.modal', function(event) {
            // Reset target to original position
            var $target = $(event.target).find('.modal-dialog');

            $target.css({
                'left': $target.data('originalLeft'),
                'top': $target.data('originalTop')
            });
        });

});