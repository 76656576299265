/**
 * Xhr can be used to load an XmlHttpRequest and put this in a target container.
 * To use it, add the .xhr class (or .tabxhr, .modalxhr) to an element. When this element is clicked
 * or submitted (in case of form), the event listener will get the url output and place it in the target container
 * or replace the target container with the output.
 *
 *
 * Important classes are:
 * .xhr                     =>  element that uses the .xhr actions
 * .tabxhr                  =>  element that uses the .xhr actions intended specifically for asset node module tabs
 * .modalxhr                =>  element that uses the .modalxhr actions
 * .edit-toggle-container   =>  element that is the container of buttons that should be toggled on or off
 * .edit-toggle-on          =>  element that should gain the class 'active'
 * .edit-toggle-off         =>  element that should remove the class 'active'
 *
 *
 * Important attributes of an xhr element are:
 * href (or 'action' in case of form)       =>  url needed for the ajax call
 * data-xhr-target-element                  =>  id of target container (with '#')
 * data-xhr-target-selector                 =>  jQuery selector which selects (single!) target container (NOTE: behavior of multiple selected target elements is UNDEFINED!)
 * data-xhr-replace-element                 =>  if present, target container will be replaced by the request output, rather than filled with it
 *
 * data-xhr-refresh-element                 =>  id of element that will be refreshed after the request
 * data-src                                 =>  source url of element that is refreshed (see refresh-element)
 * data-src-includes-this                   =>  if present, refresh element will be replaced by the refresh-output, rather than filled with it
 *
 * data-tab-name (in case of .tabxhr)       =>  name of the asset node module tab
 *
 * data-modal-result-container              =>  id of target container of the modal window result
 * data-modal-title                         =>  title of the newly opened modal window
 * data-modal-size                          =>  size of the modal window, with values: [ 'sm' | 'lg' | 'xl' ]
 * data-modal-save-button                   =>  if present, modal window will have a save button
 * data-modal-save-button-label             =>  text of the save button
 * data-modal-close-button                  =>  if present, modal window will have a close button at the bottom
 * data-modal-close-button-label            =>  text of the close button
 * data-modal-submit-show-progress          =>  if present, modal window will show a progress bar during loading
 * data-modal-modal-remains-after-submit    =>  if present, modal window will not close after its form submit
 * data-modal-close-callback                =>  if present, modal window will perform an xhr-action on its closure
 * data-modal-save-submits-form             =>  id of an (external) submit button which (if set) will be clicked when the modal window is submitted
 * data-modal-save-button-submits           =>  if present, modal window's save button will submit inner form
 * data-modal-cancel-button-class           =>  if present, extra class for giving the button a different visual
 * data-modal-save-button-class             =>  if present, extra class for giving the button a different visual
 * data-refresh-datatable                   =>  id of a Datatable that should be refreshed after the modal window's submit
 *
 * data-toggle-parent-selector              =>  jQuery selector which selects the toggle-container (see .edit-toggle-container)
 *
 */

/**
 * JQuery Extension that loads an XmlHttpRequest and executes the response scripts:
 * it either puts the response into the container, or replaces the container with the response
 */
(function($) {
    $.fn.extend({
        loadWithScripts: function (url, replaceContainer) {
            var data = typeof(arguments[1]) == "object" ? arguments[1] : {};
            var success = arguments[2] || typeof(arguments[1]) == "function" ? arguments[1] : function () { };

            return $.ajax(url, {
                context: this,
                success: [function (data) {
                    $(this).find('[data-toggle="tooltip"]').tooltip('destroy'); // Hide all tooltips in the target

                    if(!replaceContainer) {
                        $(this).html(data);
                    } else {
                        $(this).filter('[data-toggle="tooltip"]').tooltip('destroy');
                        $(this).replaceWith(data);
                    }

                    // Remove dismissed alert elements from the DOM
                    window.DismissAlerts.removeDismissedAlerts();
                }, success],
                type: "GET",
                data: data
            });
        }
    });
})(jQuery);

/**
 * Observe form submits, intercept them, and update the closest .content element with the response.
 * If no form method is given, method POST is used.
 *
 * @param e
 */
function XHRFormSubmit(e) {
    e.stopPropagation();
    e.preventDefault();

    var form = $(this),
        submitbtn = form.find('[type=submit]'),
        target = null,
        hasTargetElement = form[0].hasAttribute('data-xhr-target-element'),
        hasTargetSelector = form[0].hasAttribute('data-xhr-target-selector'),
        replaceTargetElement = form[0].hasAttribute('data-xhr-replace-element');

    if(!hasTargetElement && !hasTargetSelector) {
        target = $(form).closest('.content');
    } else if (hasTargetElement) {
        var target_id = $(form[0]).attr('data-xhr-target-element');
        target = $('#'+target_id);
    } else if (hasTargetSelector) {
        var target_selector = $(form[0]).attr('data-xhr-target-selector');
        target = $(target_selector);
    }

    // remove dirty from current form's elements so that it doesn't conflict with checkformdirty.
    //form.find('.element-dirty').removeClass('element-dirty');
    form.find('[data-dirty="true"]').removeAttr('data-dirty');
    checkFormDirty(target).then(function() {

            submitbtn.attr('disabled', true);
            submitbtn.attr('value','Bezig met opslaan...');
            submitbtn.addClass('saving');
            $.ajax({
                url: form.attr('action'),
                data: form.find(':input').serialize(),
                method: (form.attr('method') != undefined) ? form.attr('method') : 'POST'
            }).success(function(data) {
                target.find('[data-toggle="tooltip"]').tooltip('destroy'); // Hide all tooltips in the target

                if (replaceTargetElement) {
                    target.filter('[data-toggle="tooltip"]').tooltip('destroy');
                    target.replaceWith(data);
                } else {
                    target.html(data);
                }
                submitbtn.removeAttr('disabled');

                if(form[0].hasAttribute('data-xhr-refresh-element')) {
                    XHRRefreshElement(document.getElementById($(form[0]).attr('data-xhr-refresh-element')));
                }
            }).error(function(data) {
                handleModalFormErrors(form, data);
                submitbtn.removeAttr('disabled');
            });
        }, function() {
            // do nothing
        }
    )
}


/**
 * Generic hyperlink click interceptor. Add .xhr to any hyperlink or something with an href and the data will be
 * loaded into either .nodecontainer or the an element selector that's set via xhr-target-element attribute.
 *
 * Attributes:
 * xhr-target-element (single element selector by ID , with #)
 * xhr-target-selector (single element selector by ID)
 * xhr-replace-element (Allow ajax request to replace the whole container plus child (so also replace the element with the id in the response!)
 * @param e
 */
function XHRLoadRoute(e) {
    e.stopPropagation();
    e.preventDefault();
    var src = $(this),
        target = null,
        hasTargetElement = src[0].hasAttribute('data-xhr-target-element'),
        hasTargetSelector = src[0].hasAttribute('data-xhr-target-selector'),
        replaceTargetElement = src[0].hasAttribute('data-xhr-replace-element');

    if(!hasTargetElement && !hasTargetSelector) {
        target = $('.nodecontainer');
    } else if (hasTargetElement) {
        target = $('#'+src.attr('data-xhr-target-element'));
    } else if (hasTargetSelector) {
        target = $(src.attr('data-xhr-target-selector'));
    }

    if (null === target) {
        console.error("No target element found for XHRLoadRoute!", src);
        return;
    }

    checkFormDirty(target).then(
        function() {
            target.find('[data-toggle="tooltip"]').tooltip('destroy'); // Hide all tooltips in the target
            target.html('Scherm wordt geladen.. een ogenblik geduld');
            target.loadWithScripts(src.attr('href'), replaceTargetElement);
        }
    );
}


/**
 * Load a tab into the .nodecontainer element.
 *
 * @param e
 */
function XHRLoadRouteForTab(e) {
    e.stopPropagation();
    e.preventDefault();
    var self = $(this), container = $('.nodecontainer');
    checkFormDirty(container).then(function() {
        localStorage.setItem('favoriteTab', self.attr('data-tab-name'));
        container.find('[data-toggle="tooltip"]').tooltip('destroy'); // Hide all tooltips in the target
        container.html('Scherm wordt geladen.. een ogenblik geduld');
        container.loadWithScripts(self.attr('href'));
        self.closest('ul').find('li').removeClass('active');
        self.closest('li').addClass('active');
    });
    return false;
}

function XHRRefreshElement(el) {
    var url = (el.hasAttribute('data-src')) ? $(el).attr('data-src') : false;
    var urlReplacesMe = el.hasAttribute('data-src-includes-this');

    if (url) {
        $(el).loadWithScripts(url, urlReplacesMe);
    }
}

/**
 * If you add a class "modalxhr" and an "href" to an element it will load it's content into a popup.
 * Optional properties:
 * - modal-title
 * - modal-close-button
 * - modal-close-button-label
 * - modal-save-button
 * - modal-save-button-label
 * - modal-save-submits-form : selector that denotes the form that must be submitted when the save buttons is clicked
 * - modal-save-button-class: extra class for giving the button a different visual
 * - modal-cancel-button-class: extra class for giving the button a different visual
 *
 * - modal-close-callback
 * - modal-refresh-element
 * - modal-refresh-route
 */
function XHRLoadRouteForModal(e) {
    e.stopPropagation();
    e.preventDefault();

    var src = $(this),
        id = src[0].hasAttribute('data-modal-id') ? src.attr('data-modal-id') : null,
        title = src[0].hasAttribute('data-modal-title') ? src.attr('data-modal-title') : '',
        buttons = [],
        hasCloseButton = src[0].hasAttribute('data-modal-close-button'),
        closeModalWhenEscapeIsPressed = src[0].hasAttribute('data-modal-esc-closes'),
        hasSaveButton = src[0].hasAttribute('data-modal-save-button'),
        submitShowProgress = src[0].hasAttribute('data-modal-submit-show-progress'),
        modalRemainsAfterSubmit = src[0].hasAttribute('data-modal-modal-remains-after-submit'),
        textSaveButton = src[0].hasAttribute('data-modal-save-button-label') ? src.attr('data-modal-save-button-label') : '',
        textCloseButton = src[0].hasAttribute('data-modal-close-button-label') ? src.attr('data-modal-close-button-label') : '',
        classSaveButton = src[0].hasAttribute('data-modal-save-button-class') ? ' ' + src.attr('data-modal-save-button-class') : '',
        classCloseButton = src[0].hasAttribute('data-modal-close-button-class') ? ' ' + src.attr('data-modal-close-button-class') : '',
        closeCallback = src[0].hasAttribute('data-modal-close-callback') ? true : false,
        modalSaveSubmitsSelector = src[0].hasAttribute('data-modal-save-submits-form') ? src.attr('data-modal-save-submits-form') : '[type=submit]',
        size = src[0].hasAttribute('data-modal-size') ? src.attr('data-modal-size') : '',
        saveButtonSubmits = src[0].hasAttribute('data-modal-save-button-submits') ? true : false;

    if (hasCloseButton) {
        buttons.push({
            text: textCloseButton || 'Sluiten',
            style: 'danger cancelButton' + classCloseButton,
            close: true,
            click: function () {
                $(".modal-backdrop").remove();
            }
        });
    }

    if (hasSaveButton) {
        buttons.push({
            text: textSaveButton || 'Opslaan',
            style: 'info saveButton' + classSaveButton,
            close: false,
            click: function (e) {
                if (modalSaveSubmitsSelector) {
                    $(this).find(modalSaveSubmitsSelector).click();
                }
                if (saveButtonSubmits == true) {
                    $("div.modal-body form").submit();
                    e.preventDefault();
                }
                if (submitShowProgress) {
                    $('.modal-footer').hide();
                    $('.modal-body').append('<div class="progress" style="margin-top: 10px"><div class="progress-bar progress-bar-striped active" style="width: 100%"></div></div>');
                }
                $(".modal-backdrop").remove();
            }
        });
    }
    // Default modal options: static backdrop (does not allow clicking on backdrop to close it)
    eModal.setModalOptions({backdrop: 'static', keyboard: closeModalWhenEscapeIsPressed});
    eModal.ajax({
        url: src.attr('href'),
        title: title,
        buttons: buttons,
        size: size
    }).then(function(modal) {
        redecorateXHRPopup(modal, id, src, modalRemainsAfterSubmit, closeCallback);
    });
}

function redecorateXHRPopup(modal, id, trigger, modalRemainsAfterSubmit, closeCallback, submitSuccessCallback) {
    $(modal).addClass("xhrNoPadding");

    if (id) {
        $(modal).attr('id', id);
    } else {
        $(modal).attr('id', 'test123');
    }

    // if there is a form in the modal, link the submit button to it (so that the dirty-checker is linked).
    var $form = $(modal).find('form').first();
    var $button = $(modal).find('.modal-footer .saveButton');
    if ($form != undefined && $button != undefined && ! $form.hasClass('nodirtycheck')) {
        $button.attr('data-dirty-form', '#' + $form.attr('id'))
            .toggleClass('dirty-check-has', $form.hasClass('dirty-check-has'))
            .toggleClass('dirty-check-is', $form.hasClass('dirty-check-is'));
    }

    setTimeout(function() {
        if ($(modal).find('[type=submit]')[0] != undefined && $(modal).find('.modal-footer .saveButton')[0] != undefined) {
            $(modal).find('.modal-footer .saveButton')[0].value = $(modal).find('[type=submit]')[0].textContent;
        }
        if (closeCallback) {
            $(modal).on("hidden.bs.modal", function () {
                if (trigger) {
                    var target = null;
                    var href = null;
                    if (trigger[0].hasAttribute('data-modal-result-container')) {
                        target = trigger.attr('data-modal-result-container');
                    }
                    if (target != null) {
                        href = $('#' + target).attr('data-src');
                    }
                    if (target != null && href != null) {
                        var targetElement = $('#' + target);
                        targetElement.find('[data-toggle="tooltip"]').tooltip('destroy'); // Hide all tooltips in the target
                        targetElement.html('Scherm wordt geladen.. een ogenblik geduld');
                        targetElement.loadWithScripts(href);
                    }
                }
                $(".modal-body form").unbind(); //unbind. to stop multiple form submit.
            });
        } else {
            $(modal).unbind("hidden.bs.modal");
        }
        $(modal).find('[type=submit]').hide();
        $(modal).find("form").on("submit" ,function(e) {
            e.preventDefault();

            var postURL = $(this).attr("action");
            var postData = new FormData();

            var inputData = $(this).serializeArray();
            $.each(inputData, function(key, input) {
                postData.append(input.name, input.value);
            });
            $(this).find("input[type=file]").each( function() {
                if (! $(this)[0] || ! $(this)[0].files) {
                    return;
                }

                var name = $(this).attr('name'),
                    files = $(this)[0].files;
                for (var i = 0; i < files.length; i++) {
                    postData.append(name, files.item(i));
                }
            });

            $.ajax({
                url: postURL,
                type: "POST",
                data: postData,
                processData: false,
                contentType: false,
            }).then(function(data) {

                if (trigger) {
                    var target = trigger[0].hasAttribute('data-modal-result-container');
                    if (target) {
                        var resultContainer = $('#' + trigger.attr('data-modal-result-container'));
                        resultContainer.find('[data-toggle="tooltip"]').tooltip('destroy'); // Hide all tooltips in the target
                        resultContainer.html(data);
                    }
                    if (trigger[0].hasAttribute('data-refresh-datatable')) {
                        var table = $("#" + trigger.attr('data-refresh-datatable')).DataTable();
                        table.ajax.reload();
                    }

                    if(trigger[0].hasAttribute('data-xhr-refresh-element')) {
                        XHRRefreshElement(document.getElementById(trigger.attr('data-xhr-refresh-element')));
                    }
                }
                if (!modalRemainsAfterSubmit) {
                    $(".modal-body form").unbind(); //unbind. to stop multiple form submit.
                    $(modal).find('[data-toggle="tooltip"]').tooltip('destroy'); // Hide all tooltips in the target
                    eModal.close();
                }

                // fire custom event, so we can tap into this to do other stuff
                // TODO most of the other callback options (submitSuccessCallback, data-refresh-datatable, etc) should be cleaned up to only use this event.
                $.event.trigger("smo.modal.posted", [{modal: $(modal).attr('id'), response: data}]);

                if (submitSuccessCallback) {
                    submitSuccessCallback(data);
                }
            }, function(err,b,c) {
                handleModalFormErrors(modal, err);
            });
            e.preventDefault(); //STOP default action
            return false;
        });
    }, 500);
    return modal;
}

/**
 * Present modal form error messages.
 * Remove old messages in the modal and re-iterate the array.
 * @param modal
 * @param err
 */
function handleModalFormErrors(modal, err) {
    // Laravel validation errors
    if(err.status == 422 && err.responseJSON) {
        $(modal).find('.errormsg').remove();
        for (var el in err.responseJSON.errors) {
            var elm = $(modal).find("[name='" + el + "']");
            var error = err.responseJSON.errors[el];
            if (Array.isArray(error)) {
                elm.after('<div class="errormsg">' + err.responseJSON.errors[el].join('<br>') + "</div>");
            } else {
                elm.after('<div class="errormsg">' + err.responseJSON.errors[el] + '</div>');
            }
        }
    } else if (err.status == 413) {
        var readableMaxUploadSize = '10MB'; // TODO do not hardcode this, but make it dependent on config('image.max_file_size')
        swal('Fout bij uploaden van bestand', 'Het bestand moet kleiner zijn dan ' + readableMaxUploadSize, 'error');
    } else if (err.status == 403) {
        swal('Fout', 'Deze handeling is niet toegestaan.', 'error');
    }
}

function toggleEditButtons(e) {
    var parent = $(e.target).closest('.edit-toggle-container');
    if(parent.length == 0 && $(this).data('toggle-parent-selector')) {
        parent = $($(this).data('toggle-parent-selector'));
    }
    var onButton = parent.find(".edit-toggle-on");
    var offButton = parent.find(".edit-toggle-off");
    var isOff = offButton.attr('class').indexOf('active') === -1;

    if(isOff) {
        offButton.addClass('active');
        onButton.removeClass('active');
    } else {
        onButton.addClass('active');
        offButton.removeClass('active');
    }
}

window.addEventListener('DOMContentLoaded', function() {
    /**
     * Listen for click events on all a class='xhr' elements and load them into the nodeconfig
     **/
    $(document).on("click", "tr.xhr", XHRLoadRoute);
    $(document).on("click", "a.xhr", XHRLoadRoute);
    $(document).on("click", ".tabxhr", XHRLoadRouteForTab);
    $(document).on("click", ".modalxhr", XHRLoadRouteForModal);

    $(document).on("click", ".edit-toggle-off", toggleEditButtons);
    $(document).on("click", ".edit-toggle-on", toggleEditButtons);

    $(document).on("submit", "form.xhr", XHRFormSubmit);
});
