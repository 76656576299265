/**
 * This JS code allows for the Bootstrap's alert dismissal to be persisted to a cookie.
 *
 * To use:
 *  - Create a 'dismissible alert' according to Bootstrap's documentation: https://getbootstrap.com/docs/3.3/components/#alerts-dismissible
 *  - Set the 'id' property on the alert element
 *  - Enjoy
 *
 * When reloading new content using $.ajax() or some other method,
 * it is advised to run removeDismissedAlerts() in order to ensure no dismissed alerts are shown:
 *
 * window.DismissAlerts.removeDismissedAlerts();
 *
 */

function SamsonDismissAlerts() {

    function getDismissedAlerts() {
        var dismissed = Cookies.getJSON('dismissed_alerts');
        return dismissed ? dismissed : [];
    }

    // Remove the dismissed elements from the DOM.
    this.removeDismissedAlerts = function () {
        $.each(getDismissedAlerts(), function (index, id) {
            $('#' + id).remove();
        })
    };

    this.dismiss = function(id) {
        if (!id) { // No ID? Cannot store this!
            return;
        }

        var dismissed = getDismissedAlerts();

        // If we already have it, do not store it again (prevent accidental duplicates)
        if (dismissed.indexOf(id) !== -1) {
            return;
        }

        dismissed.push(id);
        Cookies.set('dismissed_alerts', dismissed);
    }
}

// Add object to window
window.DismissAlerts = new SamsonDismissAlerts();

// Add listener
window.addEventListener('DOMContentLoaded', function() {
    window.DismissAlerts.removeDismissedAlerts()
});

// If an alert is dismissed, store it in a cookie.
$('body').on('close.bs.alert', function (event) {
    window.DismissAlerts.dismiss($(event.target).attr('id'));
});
