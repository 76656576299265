/**
 * Allows you to add data-method="METHOD to links to automatically inject a form
 * with the method on click
 *
 * Example: <a href="{{route('customers.destroy', $customer->id)}}"
 * data-method="delete" name="delete_item">Delete</a>
 *
 * Injects a form with that's fired on click of the link with a DELETE request.
 * Good because you don't have to dirty your HTML with delete forms everywhere.
 */
function addDeleteForms() {
    $('[data-method]').append(function () {
        if (! $(this).find('form').length > 0)
            return "\n" +
                "<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                "   <input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "   <input type='hidden' name='_token' value='" + $('meta[name="_token"]').attr('content') + "'>\n" +
                "</form>\n";
        else
            return "";
    })
        .removeAttr('href')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', '$(this).find("form").submit();');
}

// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

// Place any jQuery/helper plugins in here.
$(function(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    /*
     Generic are you sure dialog
     */
    $('form[name=delete_item]').submit(function(){
        return confirm("Are you sure you want to delete this item?");
    });

    /**
     * Add the data-method="delete" forms to all delete links
     */
    addDeleteForms();

    /**
     * This is for delete buttons that are loaded via AJAX in datatables, they will not work right
     * without this block of code
     */
    $(document).ajaxComplete(function(){
        addDeleteForms();
    });

    /*
     Bind all bootstrap tooltips
     */
    $('body').tooltip({
        selector: '[data-toggle=\"tooltip\"]',
        trigger: 'hover',
    });
    $("[data-toggle=\"popover\"]").popover();
    //This closes the popover when its clicked away from
    $('body').on('click', function (e) {
        $('[data-toggle="popover"]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

    $.fn.focusify=function(e,t,n){return this.each(function(){var t="#3498db";switch(n){case"border":$(this).find(".focusify-disable").fadeTo("fast",.3).addClass("focusedDisable");var r=$(this).find(".focusify-enable").css("border");$(this).find(".focusify-enable").css("border","2px solid "+t).addClass("focusedEnable");setTimeout(function(){$(".focusedDisable").fadeTo("fast",1);$(".focusedEnable").css("border",r);$(".focusify-disable").removeClass("focusedDisable");$(".focusify-enable").removeClass("focusedEnable")},e);break;case"background":$(this).find(".focusify-disable").fadeTo("fast",.3).addClass("focusedDisable");var r=$(this).find(".focusify-enable").css("background-color");$(this).find(".focusify-enable").css("background-color",t).addClass("focusedEnable");setTimeout(function(){$(".focusedDisable").fadeTo("fast",1);$(".focusedEnable").css({"background-color":r});$(".focusify-disable").removeClass("focusedDisable");$(".focusify-enable").removeClass("focusedEnable")},e);break;case"reverse":$(this).find(".focusify-disable").fadeTo("fast",.3).addClass("focusedDisable");var i=$(this).find(".focusify-enable").css("background-color");var t=$(this).find(".focusify-enable").css("color");$(this).find(".focusify-enable").css({"background-color":t,color:i}).addClass("focusedEnable");setTimeout(function(){$(".focusedDisable").fadeTo("fast",1).removeClass("focusedDisable");$(".focusedEnable").css({"background-color":i,color:t}).removeClass("focusedEnable")},e);break;default:break}})}

});


(function($) {

    /**
     * Dirty checker: keeps track of forms and whether or not they contain original values or updated values.
     * When a form is not dirty (eg. contains solely original values), the corresponding submit button is given the 'btn-disabled' class.
     * When a form is dirty (eg. contains one or more updated values), the corresponding submit button is enabled normally.
     * When any form submits, all other forms are checked if they are dirty; if any of them are, an alert box will prompt the user to confirm the submission.
     *
     * To add the dirty checker to a form, run the following command:
     * $("FORM SELECTOR").areYouSure({dirtyClass: 'dirty-check-is'}).addClass('dirty-check-has');
     *
     * This command is already automatically run on forms created with form-builder.
     */
    $.fn.areYouSure = function(options) {
        var formTags = 'form:not(.nodirtycheck):not(.dirty-check-has)';
        var formElementTags = ":input:not(input[type=submit]):not(input[type=button]):not(.nodirtycheck)";
        var inputChangeEvents = 'change keyup propertychange input';
        var initializedClass = 'dirty-check-has';
        var dirtyClass = 'dirty-check-is';
        var externalSubmitButtonTag = 'data-dirty-form';

        // find relevant element
        var body = $(document.body),
            uninitialized = body.find(formTags);

        /**
         *
         * @param $form
         */
        var initFormFields = function($form) {
            //console.error("Initialize form fields!", $form);
            var fields = $form.find(formElementTags);
            $(fields).each(function() { storeOrigValue($(this)); });
            setDirtyStatus($form, false);
        };

        var storeOrigValue = function($field) {
            $field.data('original-value', getValue($field));
        };

        var getValue = function($field) {
            if ($field.attr('name') === undefined) { return null; }

            var val = '', type = $field.attr('type');
            if ($field.is('select')) {
                type = 'select';
            }

            switch (type) {
                case 'checkbox':
                case 'radio':
                    val = $field.is(':checked');
                    break;
                case 'select':
                    val = $field.find('option:selected').val();
                    val = (val === undefined) ? null : val;
                    break;
                default:
                    val = $field.val();
            }
            return val;
        };

        var isFieldDirty = function($field) {
            var origValue = $field.data('original-value');
            //console.log(origValue);
            if (undefined === origValue) {
                return false;
            }
            return getValue($field) != origValue;
        };

        var setDirtyStatus = function($form, isDirty) {
            $form.toggleClass(dirtyClass, isDirty);

            // Toggle class of any external related submit button
            $('button[' + externalSubmitButtonTag + '="#' + $form.attr('id') + '"]')
                .toggleClass(dirtyClass, isDirty);

            return isDirty;
        };

        var getDirtyFields = function(target) {
            //var fields = $(target).find(dirtyFieldSelector);
            var fields = $(target).find('[data-dirty="true"]');

            return fields.map(function(idx, el) {
                if(el.getAttribute('type') != 'hidden') return el;
                var elm = $(el);
                if(elm.data('select2') !== undefined) { // select2 check
                    return $('#'+elm.data('select2').containerId)[0];
                }
                return el;
            })
        };

        function highlightDirtyFields(target) {
            var fields = getDirtyFields(target);
            $(fields).closest('.row').addClass('focusify-enable');
            $(target).focusify(2000, '#c0392b','border');

            fields[0].scrollIntoView();
        }

        /**
         * init forms that need it
         */
        uninitialized.each(function(idx, uninitializedForm) {
            // Mark the form
            var $form = $(uninitializedForm).addClass(initializedClass);
            
            // Mark any related external submit button
            $('button[' + externalSubmitButtonTag + '="#' + $form.attr('id') + '"]')
                .addClass(initializedClass);

            // Initialize fields
            initFormFields($form);
        });

        /**
         * If dirty check hasn't been started, bind the general events.
         */
        if(!body.attr('dirty-check-inited')) {
            $(body).on(inputChangeEvents, 'form.' + initializedClass, function (evt) {
                var $form = $(this);

                // check if changed field is dirty (compare value against original)
                if (isFieldDirty($(evt.target))) {

                    //console.warn("dirteh!");

                    // find element by name and set dirty class
                    // used a data-attr to prevent class propagation to parent <div> in select2 cases.
                    // this takes some time, so the 'setDirtyStatus' sets the wrong form status.
                    $(evt.target).attr('data-dirty', 'true');

                    setDirtyStatus($form, true);
                } else {

                    // remove the dirty attr from the form element
                    $(evt.target).removeAttr('data-dirty');

                    // look for dirty elements, and set form dirty if found
                    setDirtyStatus($form, $form.find('[data-dirty="true"]').length > 0);
                }
            });

            window.onbeforeunload = function() {
                if(getDirtyFields().length == 0) {
                    return;
                } else {
                    highlightDirtyFields();
                }
                return 'Er zijn nog niet opgeslagen gegevens. Weet je zeker dat je weg wilt navigeren van deze pagina?';
            };



            // make sure that we only init once
            $(body).attr('dirty-check-inited', true);
            //console.error("Dirty check init done!!!")
        }

        /**
         * Check for dirty form elements and create a confirm message box if needed.
         */
        window.checkFormDirty = function(target) {
            var dirtyElements = getDirtyFields(target);
            if (dirtyElements.length > 0) {
                return new Promise(function(resolve, reject) {
                    swal({
                        title: 'Pagina verlaten?',
                        text: 'Deze pagina bevat niet opgeslagen gegevens, wilt u doorgaan en deze gegevens weggooien?',
                        type: 'warning',
                        showCancelButton: true,
                        cancelButtonText: 'Op pagina blijven',
                        confirmButtonText: 'Doorgaan',
                        customClass: 'dirty-confirm'
                    }, function (isConfirm) {
                        if(isConfirm) {
                            resolve();
                        } else {
                            highlightDirtyFields(target);
                            reject();
                        }
                    });
                });
            } else {
                return new Promise(function(resolve) {
                    resolve(false);
                });
            }
        };

        return this.each(function(elem) {
            //console.log("elem!");
        });

    };
})(jQuery);

/**
 * Placeholder for when no forms were on initial page load. this will be overwritten by the function above.
 * @param target
 * @returns {Promise}
 */
window.checkFormDirty = function(target) {
    return new Promise(function(resolve) {
        resolve(false);
    });
};