function SamsonChart() {

    CanvasJS.addCultureInfo("nl", {
        decimalSeparator: ",",
        digitGroupSeparator: ".",
        yValueFormatString: "#.###",
        xValueFormatString: "#.###",
        days: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        shortDays: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        months: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        shortMonths: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
    });
    this.charts = {};

    this.add = function (id, data) {
        data.culture = "nl";
        this.charts[id] = new CanvasJS.Chart(id, data);
    };

    this.render = function (id) {
        if (! this.charts.hasOwnProperty(id)) {
            console.error("Cannot render chart '" + id + "', since it does not exist!");
        }

        this.charts[id].render();
    };

    this.renderAll = function () {
        $.each(this.charts, function (id, chart) {
            chart.render();
        });
    };

}

window.addEventListener('DOMContentLoaded', function() {
    window.Chart = new SamsonChart();

    // If the sidebar is toggled, wait for half a second (due to css transitions)
    // and then render the charts again
    $(document).on("click", ".sidebar-toggle", function () {
        setTimeout(function () {
            window.Chart.renderAll();
        }, 500);
    });
});
